'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.users.factory:UserGroupManager

 # @description

###
angular
  .module 'mundoAdmin.users'
  .factory 'UserGroupManager', [
    'Restangular'
    'TenantManager'
    'UserRoleManager'
    'MundoDefaultManager'
    'UserContextManager'
    'UserPermissions'
    '$sce'
    '$mdDialog'
    '$rootScope'
    '$translate'
    (
      Restangular
      TenantManager
      UserRoleManager
      MundoDefaultManager
      UserContextManager
      UserPermissions
      $sce
      $mdDialog
      $rootScope
      $translate
    )->
      UserGroupManagerBase = new MundoDefaultManager()

      UserGroupManagerBase.setUrl('user_contexts/groups')
      UserGroupManagerBase.setAutoTenant()
      UserGroupManagerBase.setNewObject(['label', 'roles'])
      UserGroupManagerBase.setUpdateObject(['label', 'roles'])
      UserGroupManagerBase.setSearchFields(['label', 'tenant.label', 'roles.label'])


      UserGroupManagerBase.new = (data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()), {}, autoTenant: @conf.autoTenant)

      UserGroupManagerBase.update = (id, data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)
        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      UserGroupManagerBase.getNewForm = ->
        [
          key: 'label'
          type: 'input'
          name: 'label'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
            description: 'Descriptive text'
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          templateOptions:
            label: 'Roles'
            multiple: true,
            placeholder: 'roles'
            required: false
            labelProp: "label"
            valueProp: "id"
            options: UserRoleManager.getFullList({'sort' : 'label,ASC'}).$object
        ]

      UserGroupManagerBase.getEditForm = (data)->
        [
          key: 'label'
          type: 'input'
          defaultValue: data.label,
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
            description: 'Descriptive text'
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          defaultValue : data.roles
          templateOptions:
            label: 'Roles'
            multiple: true,
            placeholder: 'roles'
            required: false
            labelProp: "label"
            valueProp: "id"
            options: do (data) ->
              exclude = []
              roles = []
              for role in data.roles then exclude.push role.id
              UserRoleManager.getFullList({'sort' : 'label,ASC'}).then (data) ->
                for role in data
                  if role.id not in exclude then roles.push role
              roles
        ]

      UserGroupManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'roles.label'
          title: 'datatable.label.roles'
          multiple: 'roles'
        ,
          key: 'userContexts.user.username'
          title: 'datatable.label.members'
          multiple: 'userContexts'
          hideInList: true

        ]

      #permission needed to edit/delete an entity
      UserGroupManagerBase.editPermission = 'manage all MundoMosaUserBundle:UserContextGroup entities'

      UserGroupManagerBase.valuePostProcessor = (path, value, row) ->
        if path == 'userContexts.user.username'
          if row.userContexts?
            value = row.userContexts
              .filter (v) -> !v.deletedAt and \
              #   v.tenant.id == $rootScope.user.activeUserContext.tenant.id and \
                v.user?
              .map (v) -> v.user.username
              .sort (a, b) -> a.toLowerCase().localeCompare(b.toLowerCase())
              .join '<br>'

            return $sce.trustAsHtml value

        return value

      assignMembers =
        [
          icon: 'people'
          text: 'app.admin.actions.users.groups.assignMembers'
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/users/views/user-group-members.modal.tpl.html'
              controller: 'UserGroupMemberCtrl'
              controllerAs: 'ctrl'
              skipHide: true
              locals:
                id: id
        ]

      UserGroupManagerBase.addExtraAction(assignMembers)

      UserGroupManagerBase
  ]
